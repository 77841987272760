// Header.tsx
import { Box, Flex, Text, Link, Spacer } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const Header: React.FC = () => {
    const adminURL = process.env.REACT_APP_BACKEND_APP_URL + "/admin/";
    return (
        <Flex bg="brand.900" p={4} color="white">
            <Box p="2">
                <Text fontSize="xl">チャット履歴管理</Text>
            </Box>
            <Spacer />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mr={2}
            >
                <Link as={RouterLink} to="/dashboard" mx={4}>
                    チャット一覧
                </Link>
                <Link as={RouterLink} to="/bookmark" mx={4}>
                    ブックマーク
                </Link>
                <Link as={RouterLink} to="/pin" mx={4}>
                    クリップ
                </Link>
            </Box>
        </Flex>
    );
};

export default Header;
