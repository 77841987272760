import { useState } from "react";
import axios from "axios";

const useGetApi = <T>(url: string, initialState: T) => {
    const [data, setData] = useState<T>(initialState);
    const [loading, setLoading] = useState<boolean>(false); // 初期状態をfalseに設定
    const [error, setError] = useState<string | null>(null);

    const fetchData = async (): Promise<T> => {
        setLoading(true);
        try {
            const response = await axios.get<T>(url, { withCredentials: true });
            setData(response.data);
            return response.data;
        } catch (err) {
            setError("データの取得に失敗しました。");
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return { fetchData, data, loading, error };
};

export default useGetApi;
