import React, { useState, useEffect } from "react";
import { Text, Container, Heading, Flex, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Checkbox, useDisclosure, FormControl, FormLabel, Input, Table, Tbody, Td, Th, Thead, Tr, ListItem, UnorderedList, ModalFooter, Box } from '@chakra-ui/react';
import { Link as RouterLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useGetApi from "../hooks/useGetApi";
import usePutApi from "../hooks/usePutApi";
import useDeleteApi from "../hooks/useDeleteApi";
import Header from "../partials/Header";

const Favorite: React.FC = () => {
    useAuth();
    const [favorites, setFavorites] = useState<any[]>([]);
    const [groups, setGroups] = useState<any[]>([]);
    const favoriteBaseUrl = `${process.env.REACT_APP_BACKEND_APP_URL}/api/favorite/`;
    const { deleteData, loading: deleteLoading, error: deleteError } = useDeleteApi<any>(favoriteBaseUrl);
    const { putData: updateMemoData, loading: updateMemoLoading, error: updateMemoError } = usePutApi<any, any>(
        favoriteBaseUrl,
    );
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingFavoriteId, setEditingFavoriteId] = useState<number | null>(null);
    const [editingMemo, setEditingMemo] = useState('');

    const { fetchData: fetchFavoriteData, loading: favoriteLoading, error: favoriteError } = useGetApi<any>(
        favoriteBaseUrl,
        []
    );

    const { fetchData: fetchGroupData, loading: groupLoading, error: groupError } = useGetApi<any>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/chat-group/`,
        []
    );

    const handleDeleteFavorite = async (favoriteId: number) => {
        await deleteData({ id: favoriteId }); // Assuming the API requires an object with the id for deletion
        setFavorites(favorites.filter(favorite => favorite.id !== favoriteId)); // Update the UI by filtering out the deleted bookmark
    };

    const openEditModal = (favoriteId: number, currentMemo: string) => {
        setEditingFavoriteId(favoriteId);
        setEditingMemo(currentMemo);
        setIsEditModalOpen(true);
    };

    const handleUpdateMemo = async () => {
        if (editingFavoriteId && editingMemo) {
            await updateMemoData({
                id: editingFavoriteId,
                memo: editingMemo,
            });
            const updatedFavorites = favorites.map(favorite =>
                favorite.id === editingFavoriteId ? { ...favorite, memo: editingMemo } : favorite
            );
            setFavorites(updatedFavorites);
            setIsEditModalOpen(false); // モーダルを閉じる
        }
    };

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const fetchFavorites = await fetchFavoriteData();
                setFavorites(fetchFavorites);
            } catch (error) {
                console.error("データの取得に失敗しました。");
            }
        };
        fetchFavorites();
        const fetchGroups = async () => {
            try {
                const fetchGroups = await fetchGroupData();
                setGroups(fetchGroups);
            } catch (error) {
                console.error("グループデータの取得に失敗しました。");
            }
        };
        fetchGroups();
    }, []);


    const getGroupNameFromId = (groupId: Number) => {
        const group = groups.find((group) => group.id === groupId);
        return group ? group.name : "不明なグループ";
    };

    const getBody = (chat: any) => {
        if (chat.edited_body) {
            return `${chat.body}\n===============\n${chat.edited_body} 編集日時：${chat.chatwork_edited_at}`;
        }
        return chat.body;
    }


    return (
        <div>
            <Header />
            <Container>
                <Heading as='h2' size='md'>クリップ</Heading>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>送信日時</Th>
                            <Th>タイプ</Th>
                            <Th>グループ</Th>
                            <Th>送信者</Th>
                            <Th>本文<br />編集内容</Th>
                            <Th>メモ</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {favorites && favorites.length > 0 ? (
                            favorites.map((favorite: any) => (
                                <Tr key={favorite.chat.id}>
                                    <Td>{new Date(favorite.chat.sended_at).toLocaleString()}</Td>
                                    <Td>{favorite.chat.platform}</Td>
                                    <Td>{favorite.chat.group.name}</Td>
                                    <Td>{favorite.chat.send_from}</Td>
                                    <Td><Text>{getBody(favorite.chat)}</Text></Td>
                                    <Td><Text>{favorite.memo}</Text></Td>
                                    <Td>
                                        <div style={{ height: "100%", display: "flex", flexFlow: "wrap", "justifyContent": "flex-end" }}>
                                            <Button colorScheme="green" onClick={() => openEditModal(favorite.id, favorite.memo)} my={2}>編集</Button>
                                            <Button colorScheme="red" onClick={() => handleDeleteFavorite(favorite.id)} my={2}>削除</Button>
                                        </div>
                                    </Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td colSpan={7}>クリップがありません。</Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
                <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>メモを編集</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl>
                                <FormLabel>メモ</FormLabel>
                                <Input value={editingMemo} onChange={(e) => setEditingMemo(e.target.value)} />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleUpdateMemo}>保存</Button>
                            <Button variant="ghost" onClick={() => setIsEditModalOpen(false)}>キャンセル</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Container >
        </div >
    );
};

export default Favorite;