import React, { useState, useEffect } from "react";
import {
    Text,
    Container,
    Heading,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from '@chakra-ui/react';
import { Link as RouterLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useGetApi from "../hooks/useGetApi";
import usePutApi from "../hooks/usePutApi";
import useDeleteApi from "../hooks/useDeleteApi";
import Header from "../partials/Header";

const Bookmark: React.FC = () => {
    useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [bookmarks, setBookmarks] = useState<any[]>([]);
    const [groups, setGroups] = useState<any[]>([]);
    const [editingBookmark, setEditingBookmark] = useState<{ id: number; title: string } | null>(null);
    const bookmarkBaseUrl = `${process.env.REACT_APP_BACKEND_APP_URL}/api/bookmark/`;
    const { putData } = usePutApi<any, any>(bookmarkBaseUrl);
    const { deleteData } = useDeleteApi<any>(bookmarkBaseUrl);
    const { fetchData: fetchBookmarkData } = useGetApi<any>(bookmarkBaseUrl, []);

    const { fetchData: fetchGroupData, loading: groupLoading, error: groupError } = useGetApi<any>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/chat-group/`,
        []
    );

    useEffect(() => {
        const fetchBookmarks = async () => {
            try {
                const result = await fetchBookmarkData();
                setBookmarks(result);
            } catch (error) {
                console.error("Failed to fetch bookmarks:", error);
            }
        };
        fetchBookmarks();
    }, []);

    const handleEditBookmark = async () => {
        if (editingBookmark) {
            await putData({
                id: editingBookmark.id,
                title: editingBookmark.title
            });
            setBookmarks(
                bookmarks.map((bookmark) =>
                    bookmark.id === editingBookmark.id ? { ...bookmark, title: editingBookmark.title } : bookmark
                )
            );
            onClose();
            setEditingBookmark(null); // Reset editing bookmark
        }
    };

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const fetchGroups = await fetchGroupData();
                setGroups(fetchGroups);
            } catch (error) {
                console.error("グループデータの取得に失敗しました。");
            }
        };
        fetchGroups();
    }, []);

    const handleDeleteBookmark = async (id: number) => {
        await deleteData({ id });
        setBookmarks(bookmarks.filter((bookmark) => bookmark.id !== id));
    };

    const getGroupNameFromId = (groupId: Number) => {
        const group = groups.find((group) => group.id === groupId);
        return group ? group.name : "不明なグループ";
    };

    return (
        <div>
            <Header />
            <Container>
                <Heading as='h2' size='md'>ブックマーク</Heading>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>タイトル</Th>
                            <Th>ページ番号</Th>
                            <Th>グループ</Th>
                            <Th>キーワード</Th>
                            <Th>開始日</Th>
                            <Th>終了日</Th>
                            <Th></Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {bookmarks && bookmarks.length > 0 ? (
                            bookmarks.map((bookmark: any) => (
                                <Tr key={bookmark.id}>
                                    <Td>{bookmark.title}</Td>
                                    <Td>{bookmark.page}</Td>
                                    <Td>
                                        {groups.length > 0 ? (
                                            bookmark.groups.map((group: Number) =>
                                                <>{getGroupNameFromId(group)}<br /></>
                                            )) : (
                                            <Text>グループデータをロード中...</Text>
                                        )}
                                    </Td>
                                    <Td>{bookmark.search}</Td>
                                    <Td>{bookmark.start_date == null ? "" : new Date(bookmark.start_date).toLocaleDateString()}</Td>
                                    <Td>{bookmark.end_date == null ? "" : new Date(bookmark.end_date).toLocaleDateString()}</Td>
                                    <Td>
                                        <Button colorScheme="blue" mr={2} onClick={() => {
                                            setEditingBookmark({ id: bookmark.id, title: bookmark.title });
                                            onOpen();
                                        }}>編集</Button>
                                        <Button as={RouterLink} to={`/dashboard/?page=${bookmark.page}&search=${encodeURIComponent(bookmark.search)}&groups=${bookmark.groups.join(',')}&startDate=${bookmark.start_date}&endDate=${bookmark.end_date}`} colorScheme="blue">詳細</Button></Td>
                                    <Td><Button colorScheme="red" onClick={() => handleDeleteBookmark(bookmark.id)}>削除</Button></Td>
                                </Tr>
                            ))
                        ) : (
                            <Tr>
                                <Td colSpan={8}>ブックマークがありません。</Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>ブックマークを編集</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl>
                                <FormLabel>タイトル</FormLabel>
                                <Input
                                    value={editingBookmark?.title || ''}
                                    onChange={(e) =>
                                        setEditingBookmark({ ...editingBookmark, title: e.target.value } as { id: number; title: string })
                                    }
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleEditBookmark}>
                                保存
                            </Button>
                            <Button onClick={onClose}>キャンセル</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Container>
        </div >
    );
};

export default Bookmark;