import React, { useState, useEffect } from "react";
import { Text, Container, Heading, Flex, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Checkbox, useDisclosure, FormControl, FormLabel, Input, Table, Tbody, Td, Th, Thead, Tr, ListItem, UnorderedList, ModalFooter } from '@chakra-ui/react';
import { Link as RouterLink } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useGetApi from "../hooks/useGetApi";
import usePostApi from "../hooks/usePostApi";
import Header from "../partials/Header";
import { useSearchParams } from 'react-router-dom';
import { off } from "process";

const Dashboard: React.FC = () => {
    useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isFavoriteModalOpen, onOpen: onFavoriteModalOpen, onClose: onFavoriteModalClose } = useDisclosure();
    const [searchParams, setSearchParams] = useSearchParams();
    const PER_PAGE = 50;
    const page = parseInt(searchParams.get('page') || '1', 10);
    const [searchKeyword, setSearchKeyword] = useState(searchParams.get('search') || '');
    const [startDate, setStartDate] = useState(searchParams.get('startDate') || '');
    const [endDate, setEndDate] = useState(searchParams.get('endDate') || '');
    const [selectedGroups, setSelectedGroups] = useState<string[]>(searchParams.get('groups')?.split(',') || []);
    const [bookmarkTitle, setBookmarkTitle] = useState('');
    const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
    const [favoriteMemo, setFavoriteMemo] = useState('');


    const limit = PER_PAGE;
    const offset = (page - 1) * PER_PAGE;

    const [chats, setChats] = useState<any[]>([]);
    const [groups, setGroups] = useState<any[]>([]);
    const [totalChats, setTotalChats] = useState(0);
    const fetchChatsUrl = `${process.env.REACT_APP_BACKEND_APP_URL}/api/chat/?limit=${PER_PAGE}&offset=${(page - 1) * PER_PAGE}&search=${encodeURIComponent(searchKeyword)}&groups=${selectedGroups.join(',')}&startDate=${startDate}&endDate=${endDate}`;


    const { fetchData: fetchChatData, loading: chatLoading, error: chatError } = useGetApi<any>(
        fetchChatsUrl,
        []
    );

    const { fetchData: fetchGroupData, loading: groupLoading, error: groupError } = useGetApi<any>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/chat-group/`,
        []
    );

    // usePostApiフックの初期化
    const { postData: postBookmarkData, loading: postBookmarkLoading, error: postBookmarkError } = usePostApi<any, any>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/bookmark/`,
    );

    const { postData: postFavoriteData, loading: postFavoriteLoading, error: postFavoriteError } = usePostApi<any, any>(
        `${process.env.REACT_APP_BACKEND_APP_URL}/api/favorite/`,
    );

    useEffect(() => {
        const fetchChats = async () => {
            try {
                const fetchChats = await fetchChatData();
                console.log(fetchChats);
                setChats(fetchChats.results);
                setTotalChats(fetchChats.count);
            } catch (error) {
                console.error("データの取得に失敗しました。");
            }
        };
        fetchChats();
    }, [page]);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const fetchGroups = await fetchGroupData();
                setGroups(fetchGroups);
            } catch (error) {
                console.error("グループデータの取得に失敗しました。");
            }
        };
        fetchGroups();
    }, []);

    const totalPages = Math.ceil(totalChats / PER_PAGE); // 総ページ数を計算

    const goToPage = (pageNumber: number) => {
        setSearchParams({
            search: searchKeyword,
            groups: selectedGroups.join(','),
            startDate: startDate,
            endDate: endDate,
            page: pageNumber.toString(),
        });
    };

    const handleGroupChange = (groupId: string, isChecked: boolean) => {
        if (isChecked) {
            setSelectedGroups([...selectedGroups, groupId]);
        } else {
            setSelectedGroups(selectedGroups.filter(id => id !== groupId));
        }
    };

    const handleSearch = () => {
        setSearchParams({ page: '1', search: searchKeyword, groups: selectedGroups.join(',') });
        const fetchChats = async () => {
            try {
                const fetchChats = await fetchChatData();
                console.log(fetchChats);
                setChats(fetchChats.results);
                setTotalChats(fetchChats.count);
            } catch (error) {
                console.error("データの取得に失敗しました。");
            }
        };
        fetchChats();
        setSearchParams({
            page: '1',
            search: searchKeyword,
            groups: selectedGroups.join(','),
            startDate,
            endDate
        });
    };

    useEffect(() => {
        const groupsFromURL = searchParams.get('groups')?.split(',').filter(id => id !== '') || [];
        setSelectedGroups(groupsFromURL);
    }, [searchParams]);


    const saveBookmark = async () => {
        await postBookmarkData({
            title: bookmarkTitle,
            search: searchKeyword,
            groups: selectedGroups.join(','),
            page: page,
            start_date: startDate,
            end_date: endDate,
        });
    };

    const getBody = (chat: any) => {
        let text = "";
        if (chat.edited_body) {
            text = `${chat.body.replace(/\\n/g, '<br />')}\n===============\n${chat.edited_body.replace(/\\n/g, '<br />')} 編集日時：${chat.chatwork_edited_at}`;
        }
        text = chat.body.replace(/\\n/g, '<br />');
        return text;
    }

    const getGroupNameFromId = (groupId: string) => {
        console.log(groupId, groups)
        const group = groups.find((group) => group.id === Number(groupId));
        return group ? group.name : "不明なグループ";
    }

    // メモ入力とクリップ保存のモーダルを表示するための関数
    const openFavoriteModal = (chatId: number) => {
        setFavoriteMemo(''); // モーダルを開くたびにメモをリセット
        setSelectedChatId(chatId); // 選択されたチャットIDを設定
        onFavoriteModalOpen(); // モーダルを開く
        // ここで選択されたchatIdを何らかの方法で記録しておく必要があります
    };

    const handleStoreFavorite = async () => {
        if (selectedChatId) {
            await postFavoriteData({
                memo: favoriteMemo,
                chat_id: selectedChatId,
            });
            onFavoriteModalClose(); // 保存後にモーダルを閉じる
            setSelectedChatId(null); // 選択されたチャットIDをリセット
        }
    };

    return (
        <div>
            <Header />
            <Container>
                <Heading as='h2' size='md'>チャット履歴</Heading>
                <Flex my={4} align="center">
                    <FormControl>
                        <FormLabel>検索キーワード</FormLabel>
                        <Input
                            value={searchKeyword}
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            placeholder="キーワード検索"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>開始日</FormLabel>
                        <Input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            placeholder="開始日"
                            ml={2}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>終了日</FormLabel>
                        <Input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            placeholder="終了日"
                            ml={2}
                        />
                    </FormControl>
                </Flex>
                <Flex my={4} align='center' justifyContent="space-between">
                    {selectedGroups.length > 0 ? (
                        <UnorderedList w="50%">
                            {groups.length > 0 ? (
                                selectedGroups.map((groupId) => (
                                    <ListItem key={groupId}>{getGroupNameFromId(groupId)}</ListItem>
                                ))
                            ) : (
                                <Text>グループデータをロード中...</Text>
                            )}
                        </UnorderedList>
                    ) : (<Text w="50%">すべてのグループ</Text>)}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={onOpen}>グループ選択</Button>
                        <Button ml={2} onClick={handleSearch}>検索</Button>
                        <Input type="text" value={bookmarkTitle} onChange={(e) => setBookmarkTitle(e.target.value)} placeholder="ブックマークタイトル" w="30%" ml={8} />
                        <Button ml={2} onClick={saveBookmark} bg={"brand.700"}>ブックマーク</Button>
                    </div>
                </Flex>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent maxW="980px">
                        <ModalHeader>グループ選択</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {groups.map(group => (
                                <Flex>
                                    <Checkbox key={group.id} isChecked={selectedGroups.includes(String(group.id))} onChange={(e) => handleGroupChange(String(group.id), e.target.checked)}>
                                        【{group.id}】{group.platform}：{group.name}
                                    </Checkbox>
                                </Flex>
                            ))}
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <Flex justifyContent="center" alignItems="center" mt={4}>
                    {page > 1 && (
                        <>
                            <Button onClick={() => goToPage(1)}>最初</Button>
                            <Button onClick={() => goToPage(page - 1)} mx={2}>前へ</Button>
                        </>
                    )}
                    <Text>ページ {page} / {totalPages}</Text>
                    {page < totalPages && (
                        <>
                            <Button onClick={() => goToPage(page + 1)} mx={2}>次へ</Button>
                            <Button onClick={() => goToPage(totalPages)}>最後</Button>
                        </>
                    )}
                </Flex>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>送信日時</Th>
                            <Th>タイプ</Th>
                            <Th>グループ</Th>
                            <Th>送信者</Th>
                            <Th>本文<br />編集内容</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {chats.map((chat: any) => (
                            <Tr key={chat.id}>
                                <Td>{new Date(chat.sended_at).toLocaleString()}</Td>
                                <Td>{chat.platform}</Td>
                                <Td>{chat.group.name}</Td>
                                <Td>{chat.send_from}</Td>
                                <Td><div
                                    dangerouslySetInnerHTML={{
                                        __html: getBody(chat)
                                    }}
                                /></Td>
                                <Td><Button onClick={() => openFavoriteModal(chat.id)} colorScheme="teal">クリップ</Button></Td>
                            </Tr>
                        ))}
                    </Tbody>
                    <Modal isOpen={isFavoriteModalOpen} onClose={onFavoriteModalClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>クリップにメモを追加</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <FormControl>
                                    <FormLabel>メモ</FormLabel>
                                    <Input value={favoriteMemo} onChange={(e) => setFavoriteMemo(e.target.value)} placeholder="メモを記入" />
                                </FormControl>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={() => handleStoreFavorite()}>保存</Button>
                                <Button variant="ghost" onClick={onFavoriteModalClose}>キャンセル</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Table>
                <Flex justifyContent="center" alignItems="center" mt={4}>
                    {page > 1 && (
                        <>
                            <Button onClick={() => goToPage(1)}>最初</Button>
                            <Button onClick={() => goToPage(page - 1)} mx={2}>前へ</Button>
                        </>
                    )}
                    <Text>ページ {page} / {totalPages}</Text>
                    {page < totalPages && (
                        <>
                            <Button onClick={() => goToPage(page + 1)} mx={2}>次へ</Button>
                            <Button onClick={() => goToPage(totalPages)}>最後</Button>
                        </>
                    )}
                </Flex>
            </Container>
        </div >
    );
};

export default Dashboard;