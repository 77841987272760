import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";

import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import Bookmark from "./views/Bookmark";
import Favorite from "./views/Favorite";

const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/bookmark" element={<Bookmark />} />
                <Route path="/pin" element={<Favorite />} />
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </Router>
    );
};

export default App;
